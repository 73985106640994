<template>
  <NuxtLayout
      v-if="blogs.length > 0"
      name="center"
      class="mb-12 md:mb-18 lg:mb-24"
    >
    <h2 class="text-2xl mb-12 md:mb-16 lg:mb-20">
      {{ title ? title : $t("blog.latestBlog") }}
    </h2>
    <div
      class="grid md:gap-x-10 lg:gap-x-20 gap-y-8 md:gap-y-0 md:grid-cols-3 justify-center ease-in duration-300"
    >
      <TransitionRoot
        appear
        v-for="(blog, index) in blogs"
        class="blog-divider"
        :key="index"
        :show="true"
        enter="transition-opacity duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <BlogListing :data="blog"> </BlogListing>
      </TransitionRoot>
    </div>
    <div class="flex justify-center mt-12 md:mt-16 lg:mt-20">
      <NuxtLinkLocale :locale="switchBlogLocale(true)" to="/blog">
        <BaseButton
          class="md:w-[409px] w-44 mx-auto md:mx-0 h-[40px] md:h-[60px]"
          :text="(buttonTitle ? buttonTitle : $t('homePage.visitBlog'))"
        >
        </BaseButton>
      </NuxtLinkLocale>
    </div>
  </NuxtLayout>
</template>

<script setup>
import { TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  category: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  buttonTitle: {
    type: String,
    default: null,
  },
});

const queryString = computed(() => {
  let values = `?paged=1&posts_per_page=3`;
  values += `&orderby=date&order=DESC&cat=` + props.category;
  return values;
});

const blogs = ref([]);
const { data: blogLists } = await useBlogList(queryString);
blogs.value = blogLists.value?.data;

</script>
